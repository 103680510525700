/* In your CSS file */
.nav-pills{
  width: inherit;
}
.nav-pills .nav-link {
    background-color: #e7e7e7;
    color: rgb(228, 228, 228);
    margin-bottom: 10px;
    width: 100%;
  }
  
  .nav-pills .nav-link.active {
    background-color: #979797;
  }

  .nav-pills .nav-link:hover {
    background-color: #c4c4c4;
  }
  
  .tab-content {
    border: 1px solid #ffffff;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgb(194, 211, 205);;
  }

.tabs {
/* max-width: 1000px; */
display: flex;
flex-direction: column;
width: 95%;
max-width: 1200px;
height: inherit;
background-color: rgba(20,184,166,.1);
margin:50px;
border-radius: 10px;
box-shadow: #5d5d5d;
color: #000;
padding:50px
}
@media (max-width: 800px) {
  .tabs {
    margin:0;
    padding: 25px;
    width:100%
    }
  .nav-pills.tab-content {
    margin:50px;
  }
}
.left-panel {
    margin-left: 50px;
    
    height: auto;
    width: inherit;
    background-color: rgb(254, 254, 254);
    padding:30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    
}
.row{
  justify-content: center;
}
@media (max-width: 800px) {
  .left-panel {
    display: flex;
    margin-left: inherit;
    width: 90%;
    padding: 10px;
    justify-content: center;


  }
}
.tabs h1{
    margin:50px;
    text-align: center;
}
.text-center{
    font-family: sans-serif;
    padding: 15px;
    
}
.right-panel {
    flex-grow: 1;
    height: inherit;
    width :min-content;
    background-color:#f8f8f8;
    padding: 20px;
    margin-right: 50px;
}
@media (max-width: 800px) {
  .right-panel {
    flex-grow: 0;
    width: inherit;
    justify-content: center;
    margin:25px;

  }
}
.left-panel .nav-link {
    color: #333; /* Dark gray */
  }
.right-panel .content {
    padding: 25px;
}
