.about-us {

    display: flex;
    justify-content: center;
    justify-items: center;
    font-family: sans-serif;
    height: inherit;
    padding: 40px 20px;
  }
  
  .about-us-card {
    border: none;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    max-width: 800px;
  }
  
  .about-us .card-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #343a40;
  }
  
  .about-us .about-us-image {
    width: 150px;
    height: 150px;
    margin-top: 20px;
  }
  
  .about-us .card-text {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #495057;
    margin-bottom: 20px;
  }
  
  @media (max-width: 767px) {
    .about-us .card-title {
      font-size: 2rem;
    }
  
    .about-us .card-text {
      font-size: 1rem;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .about-us .card-title {
      font-size: 2.2rem;
    }
  
    .about-us .card-text {
      font-size: 1.1rem;
    }
  }
  
  @media (min-width: 992px) {
    .about-us .card-title {
      font-size: 2.5rem;
    }
  
    .about-us .card-text {
      font-size: 1.2rem;
    }
  }
  