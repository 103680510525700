.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 90vh;
    background: linear-gradient(to bottom, #ffffff, #e4e4e4be , #ffffff); /* Replace with your desired gradient colors */

  }
  
  .title {
    font-size: 3em;
    font-weight: bold;
    margin-bottom: 1em;
    text-align: center;

  }
  
  .subtitle {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 2em;
  }
  
  .description {
    font-size: 1.2em;
    margin-bottom: 2em;
    text-align: center;
  }
  
  .button {
    background-color: #007bff;
    color: white;
    padding: 1em 2em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 2em;
  }
  
  .info {
    font-size: 1em;
    margin-bottom: 1em;
  }
  
  .icon {
    margin-right: 0.5em;
  }
  
  .loved {
    font-size: 1em;
    margin-bottom: 1em;
  }
  
  .user-images {
    display: flex;
    margin-left: 0.5em;
  }
  
  .user-images img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .tabs-container {
    display: flex;
    justify-content: center;
    height: auto;

  }
  .faq {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
/* Styles for mobile devices */
@media (max-width: 800px) {
  .title {
      font-size: 3em;
      font-weight: bold;
      margin-bottom: 1em;
      margin:10px
  }
  .main-container{
    height: inherit;
  }
}