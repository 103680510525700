.disclaimer {
    padding: 40px 20px;
    background-color: #f8f9fa;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    justify-items: center;

  }
  
  .disclaimer-card {
    max-width: 800px;
    border: none;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .disclaimer .card-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0px;
    color: #343a40;
  }
  
  .disclaimer .card-subtitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #6c757d;
  }
  
  .disclaimer .card-text {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #495057;
    margin-bottom: 20px;
  }
  
  @media (max-width: 767px) {
    .disclaimer .card-title {
      font-size: 2rem;
    }
  
    .disclaimer .card-subtitle {
      font-size: 1rem;
    }
  
    .disclaimer .card-text {
      font-size: 1rem;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .disclaimer .card-title {
      font-size: 2.2rem;
    }
  
    .disclaimer .card-subtitle {
      font-size: 1.1rem;
    }
  
    .disclaimer .card-text {
      font-size: 1.1rem;
    }
  }
  
  @media (min-width: 992px) {
    .disclaimer .card-title {
      font-size: 2.5rem;
    }
  
    .disclaimer .card-subtitle {
      font-size: 1.2rem;
    }
  
    .disclaimer .card-text {
      font-size: 1.2rem;
    }
  }
  