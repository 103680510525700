/* CSS for CheckoutForm component */

.payview {
  position: fixed;
  top: 10%;
  left: 30%;
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* ensure it overlays other content */
  animation: fadeIn 0.5s ease-in-out;
}

.payment-information {
  background:linear-gradient(#ffffff,#f3f3f3);
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.5s ease-in-out;
}

.payment-information h1 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.payment-information h2 {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
}

.payment-information button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.payment-information button:hover {
  background-color: #0056b3;
}

.payment-information button:last-child {
  background-color: #dc3545;
}

.payment-information button:last-child:hover {
  background-color: #bd2130;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .payment-information {
    max-width: 90%;
  }
  .payview {
    position: fixed;
    top: 10%;
    left: 0%;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}
