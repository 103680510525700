.terms-conditions {
    padding: 20px;
    justify-content: center;
    display: flex;
    font-family: sans-serif;
  }
  
  .terms-conditions .card {
    margin-top: 20px;
    max-width: 800px;
  }
  
  .terms-conditions .card-title {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .terms-conditions .card-subtitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .terms-conditions p,
  .terms-conditions ul {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .terms-conditions ul {
    padding-left: 20px;
  }
  
  @media (max-width: 767px) {
    .terms-conditions .card-title {
      font-size: 1.5rem;
    }
  
    .terms-conditions .card-subtitle {
      font-size: 1rem;
    }
  
    .terms-conditions p,
    .terms-conditions ul {
      font-size: 0.9rem;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .terms-conditions .card-title {
      font-size: 1.8rem;
    }
  
    .terms-conditions .card-subtitle {
      font-size: 1.1rem;
    }
  
    .terms-conditions p,
    .terms-conditions ul {
      font-size: 0.95rem;
    }
  }
  
  @media (min-width: 992px) {
    .terms-conditions .card-title {
      font-size: 2rem;
    }
  
    .terms-conditions .card-subtitle {
      font-size: 1.2rem;
    }
  
    .terms-conditions p,
    .terms-conditions ul {
      font-size: 1rem;
    }
  }
  