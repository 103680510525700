.contact-us {
    padding: 40px 20px;
    background-color: #f8f9fa;
    display: flex;
    justify-content: center;
    justify-items: center;
    font-family: sans-serif;
  }
  
  .contact-us-card {
    border: none;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    max-width: 800px;
  }
  
  .contact-us .card-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #343a40;
  }
  
  .contact-us .card-text {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #495057;
    margin-bottom: 20px;
  }
  
  .contact-us a {
    color: #000000;
    text-decoration: none;
  }
  
  .contact-us a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 767px) {
    .contact-us .card-title {
      font-size: 2rem;
    }
  
    .contact-us .card-text {
      font-size: 1rem;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .contact-us .card-title {
      font-size: 2.2rem;
    }
  
    .contact-us .card-text {
      font-size: 1.1rem;
    }
  }
  
  @media (min-width: 992px) {
    .contact-us .card-title {
      font-size: 2.5rem;
    }
  
    .contact-us .card-text {
      font-size: 1.2rem;
    }
  }
  