/* Login.css */
.forgot_button{
  font-size: 100px;
}
.login-signup{
  width: 200px;
  height: inherit;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.login-form {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 400px;
  text-align: center;
}

.login-form h1 {
  margin-bottom: 20px;
  color: #333;
}

.login-form h3 {
  margin-bottom: 20px;
  color: #555;
}

.login-form .form-control {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
}

.login-form .form-control:focus {
  outline: none;
  box-shadow: 0 0 0 2px #2196f3;
}

.login-form .login-row {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.login-form button {
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #2196f3;
  color: #fff;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #1976d2;
}

.login-form .g-btn {
  background-color: #4285f4;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form .g-btn:hover {
  background-color: #3578e5;
}

.login-form hr {
  margin: 15px 0;
  border: 0;
  border-top: 1px solid #ced4da;
}

.signup-container {
  text-align: center;
}

.signup-container .p-4 {
  background-color: #f8f9fa;
  border-radius: 5px;
}

.signup-container a {
  color: #2196f3;
  text-decoration: none;
}

.signup-container a:hover {
  text-decoration: underline;
}

/* Responsive design */
@media (max-width: 576px) {
  .login-form {
    width: 90%;
  }
  .login-form button {
    width:100%;
    border: none;
    border-radius: 5px;
    padding: 10px,20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #2196f3;
    color: #fff;
    transition: background-color 0.3s ease;
  }
  .login-row{
    display: flex;
    flex-direction: column;
  }
}
.my-alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  max-width: 100%;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
  text-align: left;
}
.alert{
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.alert .btn-close{
  width: inherit;
  height: inherit;
  margin:10px;
}
.error_container{
  display: flex;
  flex-direction: row-reverse;
  justify-content: left;
  align-items: center;
  align-content: center;
  align-self: center;
}
.error_message{
  
  text-align: left;
  font-size: 20px;
}