/* Header.css */

.auth-button {
  margin-left: 10px;
}

.navbar-container {
  display: flex;
  justify-content: flex-end; /* Aligns the navbar to the right */
}

.navbar-container .navbar {
  width: inherit; /* Ensures the navbar width is only as wide as its contents */
}
.me-auto {
  width: 90vw;
  justify-content: flex-end;
  font-size: 20px;
}
/* Header.css */

/* Mobile version styles */
@media (max-width: 800px) {
  .navbar-container {
    width: 100%;
    justify-content: end;
  }

  .header-menu {
    display: flex;
    flex-direction: row;
    
  }

  .navbar-light .navbar-toggler {
    border-color: transparent;
  }


  .header-menu a img {
    margin: 10px auto;
  }

  .navbar-nav {
    flex-direction: column;
    width: 100%;
    text-align: center;
  }

  .navbar-nav .nav-link {
    padding: 10px 20px;
    margin: 5px 10px;
    border-radius: 5px;
    width: 100%;
    text-align: left;
    background-color: rgb(222, 222, 222);
  }
  .auth-button1 {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    border-color: #fff;
    background-color: rgb(5, 24, 56);
  }
}
