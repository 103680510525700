
.main_container{
    display: flex;
    flex-direction: row;
    width: inherit;
    height: inherit;
    font-family: sans-serif;
}
.panel{
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: left;
    justify-content: space-between;
    border-right: ridge;
    font-family: sans-serif;
    padding: 10px;
    background-color: rgba(201, 201, 201, 0.202);
    
}
.panel-head{
  font-family:  sans-serif;
  background-color: #cccccc7f;
  padding: 10px;
  border-radius: 8px;
  font-size: 18px;
  text-align: left;
}
.panel_holder {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin-bottom: 50%;
    font-family: sans-serif;
}
.panel_button{
    border-color: none;
    font-size:large;
    text-align: left;
    border-style: hidden;
    background-color: rgba(237, 237, 237, 0); 
    border-radius: 15px;
    padding: 10px;
    
}
.panel_button:hover{
    background-color: rgba(186, 186, 186, 0.543);

}

.user-info {
  /* Ensure the text does not overflow the container */
  word-wrap: break-word;
  /* Set a maximum width for the container */
  max-width: inherit;
  /* Optional: Add some padding and margin for spacing */
  padding: 10px;
  /* Optional: Use flexbox or grid to manage layout */
  display: flex;
  flex-direction: column;
}

.user-info p {
  /* Ensure text is wrapped within the container */
  word-wrap: break-word;
  /* Set ellipsis for overflow text */
  overflow: hidden;
  text-overflow: ellipsis;
  /* Set a maximum width for the text block */
  max-width: 100%;
  font-size: 15px;
}

.user-info p br {
  display: block; /* Ensure line breaks are properly handled */
}

.main {
    display: flex;
    flex-direction: column;
    width:auto;
    height: inherit;
    align-items: center;
    border-radius: 10px;
    background-color: rgba(237, 237, 237, 0);
}
.chatbot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end; /* Align items to the bottom */
    width: 900px;
    margin-left:10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    height: 100%;
    
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-family: sans-serif;
  }
  .chatbot h1{
    background-color:#dcdcdc98;
    width: inherit;
    border-top-left-radius: 10px;
    font-size: 25px;
    text-align: center;
    padding:10px;
    font-family: sans-serif;
    margin-bottom: 0px;
  }

  
  .buttons {
    display: flex;
    gap: 10px;
  }
  
  .button {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 8px 12px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #e0e0e0;
  }
  
  .toggle {
    display: flex;
    align-items: center;
  }
  
  .toggle label {
    margin-right: 10px;
  }

  
  .input-area {
    display: flex;
    margin-left:10px;
    width: 100%;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    resize: none;
    font-size: large;
  }
  
  .send-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .send-button:hover {
    background-color: #45a049;
  }
  
  .web-access {
    display: flex;
    width: inherit;
    justify-content: right;
    padding: 10px;
  }
  
  .web-access label {
    text-align: center;
    align-self: center;
    padding: 10px;
    font-size: large;
  }
.main-mobile{
  width: inherit;
  height: inherit;
}
.chatbot-mobile{
  width: inherit;
  height: inherit;
}
.llms{
  margin:10px;
  width: inherit;
}
.llms-button{
  padding:10px;
  width: 50%;
  border: #ddd;
}
  .llms-button-active{
    width: 50%;
  background-color: #a3a3a3;
  border: #ddd;
  padding:10px;

  }
.side_panel{
  width: 75%;
}
.home{
  width: inherit;
}
