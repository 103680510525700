/* Signup.css */
.error_container{
  display: flex;
  flex-direction: row-reverse;
  justify-content: left;
  align-items: center;
  margin-bottom: 10px;
}
.error_message{
  padding: 5px;
}
/* Container and Form Styles */
.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.signup-form {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

/* Image Style */
.custom-image {
  display: block;
  margin: 0 auto 20px;
}

/* Headings and Text Styles */
.signup-form h3 {
  margin-bottom: 10px;
  color: #333;
}

.signup-form p {
  margin-bottom: 20px;
  color: #555;
  text-align: left;
}

/* Form Control Styles */
.signup-form .form-control {
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
}

.signup-form .form-control:focus {
  outline: none;
  box-shadow: 0 0 0 2px #2196f3;
}

/* Button Styles */
.signup-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.signup-button {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #2196f3;
  color: #fff;
  transition: background-color 0.3s ease;
}

.signup-button:hover {
  background-color: #1976d2;
}

/* Alert and HR Styles */
.signup-form .alert {
  text-align: left;
}

.signup-form hr {
  margin: 30px 0;
  border: 0;
  border-top: 1px solid #ced4da;
}

/* Link Container Styles */
.login-container {
  text-align: center;
}

.login-container .p-4 {
  background-color: #f8f9fa;
  border-radius: 5px;
}

.login-container a {
  color: #2196f3;
  text-decoration: none;
}

.login-container a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 576px) {
  .signup-form {
      width: 100%;
      padding: 20px;
  }

  .signup-button {
      width: 100%;
  }

  .signup-row {
      flex-direction: column;
  }
}
