/* styles.css (or your preferred CSS file) */

.footer {
    color: #000000;
    padding: 30px 0;
  }
  
  .footer h5 {
    color: #000000;
  }
  
  .footer ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer ul li {
    margin-bottom: 10px;
  }
  
  .footer ul li a {
    color: #000000;
    text-decoration: none;
    transition: color 0.5s ease;
  }
  
  .footer ul li a:hover {
    color: #818181;
  }
  