.pricing {
  text-align: center;
  padding: 2rem;
  background: linear-gradient(to bottom, #e7e4e3, #78aae7); /* Replace with your desired gradient colors */
}

.billing-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.billing-button1 {
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-top-left-radius: 10px;
  border-width: 0;
}
.billing-button2 {
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-width: 0;
}

.billing-button.active {
  font-weight: bold;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.pricing-card {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 1rem;
  width: 400px;

}
@media (max-width: 800px) {
  .pricing-cards{
    display: flex;
    flex-direction: column;
  }
  .pricing-card{
    width: inherit;
  }
}

.pricing-card h2 {
  margin: 0;
}

.price {
  font-size: 2rem;
}

.price span {
  font-size: 1rem;
}

.subscribe-button {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 25px;

}

.advantages {
  text-align: left;
}

/* .advantages ul {
  list-style-type: none;
  padding: 0;
} */

/* .advantages ul li {
  margin: 0.5rem 0;
} */
.advantages h4{
  text-align: center;
  margin-bottom: 10px;
}
.checkoutview{

  width: 100%;

  height: auto;
  display: flex;
  justify-content: center; /* Aligns items horizontally (along the main axis) */


}
.payview{
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 800px;
  align-items: center;
  justify-items:center;
}
.payment-information{
  width: 50%
}
.checkoutform{
  width: 50%;
  justify-self: left;
}