.privacy-policy {
    display: flex;
    padding: 20px;
    justify-content: center;
    justify-items: center;
    font-family: sans-serif;

  }
  
  .privacy-policy .card {
    margin-top: 20px;
    max-width: 800px;
  }
  
  .privacy-policy .card-title {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .privacy-policy .card-subtitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .privacy-policy p,
  .privacy-policy ul {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .privacy-policy ul {
    padding-left: 20px;
  }
  
  @media (max-width: 767px) {
    .privacy-policy .card-title {
      font-size: 1.5rem;
    }
  
    .privacy-policy .card-subtitle {
      font-size: 1rem;
    }
  
    .privacy-policy p,
    .privacy-policy ul {
      font-size: 0.9rem;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .privacy-policy .card-title {
      font-size: 1.8rem;
    }
  
    .privacy-policy .card-subtitle {
      font-size: 1.1rem;
    }
  
    .privacy-policy p,
    .privacy-policy ul {
      font-size: 0.95rem;
    }
  }
  
  @media (min-width: 992px) {
    .privacy-policy .card-title {
      font-size: 2rem;
    }
  
    .privacy-policy .card-subtitle {
      font-size: 1.2rem;
    }
  
    .privacy-policy p,
    .privacy-policy ul {
      font-size: 1rem;
    }
  }
  